import { CognitoUser } from '@aws-amplify/auth';
import { Auth, Hub } from 'aws-amplify';

import User from '../../models/user';
import { IUserPermissions } from '../../interfaces/IAuthState';

export enum UserRoles {
  ADMIN = 'APP-UK-CavendishAdmin',
  SUPPLY_CHAIN = 'APP-UK-CavendishSupplyChain',
  TERMINALS = 'APP-UK-CavendishTerminals',
  AGENT_MANAGER = 'APP-UK-CavendishAgentManager',
  AGENT = 'APP-UK-CavendishAgent',
  WAREHOUSE_OPS = 'APP-UK-CavendishWarehouseOps',
  BACK_OFFICE = 'APP-UK-CavendishBackOffice',
  DEVICE_ENROLMENT = 'APP-UK-CavendishDeviceEnrolment'
}

export const getUser = async (): Promise<any> => {
  const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser();

  return new User(cognitoUser);
};

export function getUserPermissions(profile: string): IUserPermissions {
  const userRole = Object.values(UserRoles).find((r: string) =>
    profile.includes(r)
  );

  switch (userRole) {
    case UserRoles.ADMIN:
      return {
        viewSkuGroups: true,
        viewOrderReports: true,
        changeStockLevel: true,
        viewConfigInfo: true,
        viewOrderPayloadButton: true,
        viewPriorityModal: true,
        viewDispatchButton: true,
        viewUploadGoodsInFileButton: true,
        viewTradeInGoodsInButton: true,
        viewDownloadPreAlertFile: true,
        viewReleasePreordersButton: true,
        viewEditSubinventoryButton: true,
        viewUploadDpdDataButton: true,
        viewUploadMasterDeviceListButton: true,
        viewForecastingData: true,
        viewBulkReleasePreordersButton: true,
        viewDeviceEnrolmentReport: true
      };
    case UserRoles.TERMINALS:
      return {
        viewSkuGroups: true,
        viewOrderReports: true,
        changeStockLevel: true,
        viewConfigInfo: true,
        viewOrderPayloadButton: false,
        viewPriorityModal: true,
        viewDispatchButton: false,
        viewUploadGoodsInFileButton: false,
        viewTradeInGoodsInButton: false,
        viewDownloadPreAlertFile: false,
        viewReleasePreordersButton: true,
        viewEditSubinventoryButton: true,
        viewUploadDpdDataButton: false,
        viewUploadMasterDeviceListButton: true,
        viewForecastingData: false,
        viewBulkReleasePreordersButton: false,
        viewDeviceEnrolmentReport: false
      };
    case UserRoles.AGENT:
      return {
        viewSkuGroups: false,
        viewOrderReports: false,
        changeStockLevel: false,
        viewConfigInfo: false,
        viewOrderPayloadButton: false,
        viewPriorityModal: false,
        viewDispatchButton: false,
        viewUploadGoodsInFileButton: false,
        viewTradeInGoodsInButton: false,
        viewDownloadPreAlertFile: false,
        viewReleasePreordersButton: false,
        viewEditSubinventoryButton: false,
        viewUploadDpdDataButton: false,
        viewUploadMasterDeviceListButton: false,
        viewForecastingData: false,
        viewBulkReleasePreordersButton: false,
        viewDeviceEnrolmentReport: false
      };
    case UserRoles.AGENT_MANAGER:
      return {
        viewSkuGroups: false,
        viewOrderReports: false,
        changeStockLevel: false,
        viewConfigInfo: false,
        viewOrderPayloadButton: false,
        viewPriorityModal: false,
        viewDispatchButton: false,
        viewUploadGoodsInFileButton: false,
        viewTradeInGoodsInButton: false,
        viewDownloadPreAlertFile: false,
        viewReleasePreordersButton: false,
        viewEditSubinventoryButton: false,
        viewUploadDpdDataButton: false,
        viewUploadMasterDeviceListButton: false,
        viewForecastingData: false,
        viewBulkReleasePreordersButton: false,
        viewDeviceEnrolmentReport: false
      };
    case UserRoles.SUPPLY_CHAIN:
      return {
        viewSkuGroups: true,
        viewOrderReports: true,
        changeStockLevel: true,
        viewConfigInfo: true,
        viewOrderPayloadButton: false,
        viewPriorityModal: true,
        viewDispatchButton: false,
        viewUploadGoodsInFileButton: false,
        viewTradeInGoodsInButton: false,
        viewDownloadPreAlertFile: false,
        viewReleasePreordersButton: true,
        viewEditSubinventoryButton: true,
        viewUploadDpdDataButton: false,
        viewUploadMasterDeviceListButton: false,
        viewForecastingData: true,
        viewBulkReleasePreordersButton: false,
        viewDeviceEnrolmentReport: false
      };
    case UserRoles.WAREHOUSE_OPS:
      return {
        viewSkuGroups: true,
        viewOrderReports: true,
        changeStockLevel: false,
        viewConfigInfo: true,
        viewOrderPayloadButton: false,
        viewPriorityModal: false,
        viewDispatchButton: true,
        viewUploadGoodsInFileButton: false,
        viewTradeInGoodsInButton: false,
        viewDownloadPreAlertFile: false,
        viewReleasePreordersButton: false,
        viewEditSubinventoryButton: false,
        viewUploadDpdDataButton: false,
        viewUploadMasterDeviceListButton: false,
        viewForecastingData: false,
        viewBulkReleasePreordersButton: false,
        viewDeviceEnrolmentReport: false
      };
    case UserRoles.BACK_OFFICE:
      return {
        viewSkuGroups: true,
        viewOrderReports: true,
        changeStockLevel: false,
        viewConfigInfo: false,
        viewOrderPayloadButton: false,
        viewPriorityModal: false,
        viewDispatchButton: false,
        viewUploadGoodsInFileButton: false,
        viewTradeInGoodsInButton: false,
        viewDownloadPreAlertFile: false,
        viewReleasePreordersButton: false,
        viewEditSubinventoryButton: false,
        viewUploadDpdDataButton: false,
        viewUploadMasterDeviceListButton: false,
        viewForecastingData: false,
        viewBulkReleasePreordersButton: false,
        viewDeviceEnrolmentReport: false
      };
    case UserRoles.DEVICE_ENROLMENT:
      return {
        viewSkuGroups: false,
        viewOrderReports: false,
        changeStockLevel: false,
        viewConfigInfo: false,
        viewOrderPayloadButton: false,
        viewPriorityModal: false,
        viewDispatchButton: false,
        viewUploadGoodsInFileButton: false,
        viewTradeInGoodsInButton: false,
        viewDownloadPreAlertFile: false,
        viewReleasePreordersButton: false,
        viewEditSubinventoryButton: false,
        viewUploadDpdDataButton: false,
        viewUploadMasterDeviceListButton: false,
        viewForecastingData: false,
        viewBulkReleasePreordersButton: false,
        viewDeviceEnrolmentReport: true
      };
    default:
      return {
        viewSkuGroups: false,
        viewOrderReports: false,
        changeStockLevel: false,
        viewConfigInfo: false,
        viewOrderPayloadButton: false,
        viewPriorityModal: false,
        viewDispatchButton: false,
        viewUploadGoodsInFileButton: false,
        viewTradeInGoodsInButton: false,
        viewDownloadPreAlertFile: false,
        viewReleasePreordersButton: false,
        viewEditSubinventoryButton: false,
        viewUploadDpdDataButton: false,
        viewUploadMasterDeviceListButton: false,
        viewForecastingData: false,
        viewBulkReleasePreordersButton: false,
        viewDeviceEnrolmentReport: false
      };
  }
}

export default async function mountHubListener(
  setState: CallableFunction
): Promise<void> {
  Hub.listen('auth', async ({ payload: { event, data } }) => {
    switch (event) {
      case 'cognitoHostedUI': {
        const user = await getUser();
        setState({
          authState: 'signedIn',
          user,
          permissions: getUserPermissions(user?.userData.attributes.profile)
        });
        break;
      }
      case 'cognitoHostedUI_failure':
        setState({
          authState: 'signedOut',
          user: undefined,
          error: data
        });
        break;
      default:
        break;
    }
  });
}
